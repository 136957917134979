.page-wrapper {
  min-height: 450px;
}

#tc-container {
  padding-bottom: 16px;
  a {
    color: #006795;
    &:hover{
      -webkit-font-smoothing: antialiased;
      -webkit-text-stroke: 0.4px #006795;
    }
  }
}

#activate-account-link-container{
  text-align: center;
  padding-top: 8px;
  a {
    text-decoration: none;
    line-height: 32px;
    width: 388px;
    border: 2px solid white;

    &:hover {
      text-decoration: underline;
    }
    &:focus-visible {
      border: 2px solid #006795;
      outline-color: #006795;
      border-radius: 4px;
      text-decoration: underline;
    }
  }
}

#title:focus {
  outline: none;
}
