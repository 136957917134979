$font-default: Arial, Helvetica, sans-serif;
$font-regular: 'sofiapro-regular', $font-default;
$font-light: 'sofiapro-light', $font-default;
$font-bold: 'sofiapro-bold', $font-default;

// Pallete
$efficio-palette-text-grey:  var(--efficio-palette-text-grey);
$efficio-palette-light-grey:  var(--efficio-palette-light-grey);
$efficio-palette-dark-grey: var(--efficio-palette-dark-grey);
$efficio-palette-light-blue: var(--efficio-palette-light-blue);

// Primary
$efficio-palette-blue-dark: var(--efficio-palette-blue-dark);
// Doesnt seem to exist?
$efficio-palette-blue-off-dark: var(--efficio-palette-blue-off-dark);
$efficio-palette-blue-light: var(--efficio-palette-blue-light);
$efficio-palette-grey: var(--efficio-palette-grey);

// Secondary
$efficio-palette-green: var(--efficio-palette-green);
$efficio-palette-red: var(--efficio-palette-red);

// Tertiary
$efficio-palette-background-grey: var(--efficio-palette-background-grey);
$efficio-palette-marine: var(--efficio-palette-marine);
$efficio-palette-aqua: var(--efficio-palette-aqua);
$efficio-palette-yellow: var(--efficio-palette-yellow);
$efficio-palette-magenta: var(--efficio-palette-magenta);
$efficio-palette-white: var(--efficio-palette-white);
$efficio-palette-cyan-blue: var(--efficio-palette-cyan-blue);