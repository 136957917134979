@import "./vars";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-regular;
  font-size: 16px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-wrapper {
  background: #f3f3f3;
  height: 100vh;
}

.page-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.sign-in-box-container {
  width: 472px;
  background-color: #ffffff;
  color: #0d2240;
  border-radius: 8px;
  padding-bottom: 42px;
  padding-left: 42px;
  padding-right: 42px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.heading-container {
  margin-top: 31px;
  margin-bottom: 28px;

  > h3 { font-size: 26px; }
}

.contact-container {
  margin-top: 28px;
  margin-bottom: 28px;
  display: flex;

  :first-child { margin-right: 4px; }

  font-size: 14px;
  color: #0d2240;
}

.form .form-control:not(:first-child) { margin-top: 16px; }

@mixin focusState {
  outline: none;
  border: 2px solid #006795;
  border-radius: 4px;
}

.has-error-message {
  color: #e00034;
  margin-bottom: 23px;
  margin-top: -5px;
  font-size: 14px;
}

.form-control {
  display: grid;

  > input {
    &.has-error { border: 2px solid #e00034; }

    margin-top: 5px;
    margin-bottom: 11px;
    width: 100%;
    height: 48px;
    border: 1px solid #8c8c8c;
    padding: 0 10px;
    border-radius: 4px;
    font-size: 16px;
    text-decoration: none solid rgb(255, 255, 255);

    &:focus {
      outline: none;
      @include focusState();
    }
  }
  .password-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > a {
      height: 19px;
      font-family: $font-regular;
      font-size: 14px;
      &:focus {
        padding-bottom: 20px;
      }
    }
  }
}

.help {
  outline: none;
  color: #001969;
  &:focus {
    border: 2px solid #001969;
    border-radius: 4px;
    margin: -2px;
  }
  &:hover {
    -webkit-text-stroke: 0.4px #001969;
    -webkit-font-smoothing: antialiased;

  }
}

.left-align {
  text-align: left;
}

.btn {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  font-size: 16px;

  &:focus-visible {
    outline: none;
    border: 2px solid #006795 !important;
    border-radius: 4px !important;
  }
}

.inverted {
  color: var(--efficio-palette-blue-light) !important;
  border: none;

  &:hover {
    transition: 0.2s;
    background-color: var(--efficio-palette-blue-light) !important;
    color: var(--efficio-palette-white) !important;
    text-decoration: underline;
  }
}

.d-none { display: none; }

.opacity05 { opacity: 0.5; }

.img-container { margin-bottom: 37px; }

.btn-container {
  > .btn ~ .btn {
    margin-top: 9px;
  }
}

label {
  font-weight: bold;
  font-size: 16px;
  color: #0d2240;
}

.page-info { margin-top: 16px; }

.password-validation-info {
  margin-top: 17px;

  > p { margin-bottom: 20px; }

  .validation-rules {
    padding-left: 22px;

    .list-item {
      display: flex;
      position: relative;
      > p { margin-left: 2px; }
    }
  }
}

.logo {
  color: white;
  position: absolute;
  top: -5px;
  margin-left: -166px;
  left: 50%;
  height: 147px;
  width: 331px;
  z-index: 2;
  text-align: right;

  .logo--img {
    height: 147px;
    width: 331px;
  }
  .logo--text {
    position: relative;
    top: -36px;
    left: -48px;
  }
}

.spinnerContainer {
  position: absolute;
  margin-top: 30px;
  z-index: 2;
  width: 40px;
  height: 40px;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

@mixin flash-message {
  width: 388px;
  font-family: $font-regular;
  color: #0c2340;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  > p {
    font-size: 14px;
  }
}

@mixin flash-message--exit {
  font-family: $font-regular;
  font-size: 20px;
  color: #006795;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus { text-decoration: underline }
}

.success-message {
  @include flash-message();
  padding: 8px;
  border-color: #00ae65;
}

.warning-message {
  @include flash-message();
  border-color: #e00034;
  > p {
    margin-left: 12px;
  }
}

.success-message--exit {
  @include flash-message--exit();
  position: relative;
  bottom: 7px;
  margin-right: 4px;
  margin-left: 20px;
}

.success-message-info {
  margin-left: 13px;
}

.warning-message--exit {
  @include flash-message--exit();
  position: relative;
  bottom: 7px;
  margin-right: 4px;
  margin-left: 8px;
}

@mixin checkmark-container {
  z-index: 999;
}

.checkmark-holder {
  @include checkmark-container;
  position: absolute;
  right: 370px;
}

.success-message--checkmark-holder {
  @include checkmark-container;
  margin-right: 13px;
}

.checkmark {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #00ae65;
  border-radius: 50%;
  transform: rotate(45deg);
  position: absolute;
  top: 1.8px;

  &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 8px;
    background-color: #fff;
    left: 9px;
    top: 4px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 2px;
    background-color: #fff;
    left: 6px;
    top: 11px;
  }
}

.bullet {
  &:before {
    content: "";
    position: absolute;
    top: 9px;
    right: 7px;
    width: 4px;
    height: 4px;
    background-color: black;
    border-radius: 50%;
  }
}

.flash-message-checkmark-container {
  position: relative;
  bottom: 0.6px;
}

.error-activation-url-expired {
  margin-top: 20px;
  margin-bottom: -12px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid red;
  padding: 8px;
  color: #5e6a71;
}

.navigation-bar {
  background-color: #f3f3f4;
  position: relative;
  width: 100%;
}

.navigation-bar--bg {
  height: 184px;
  overflow: hidden;
  z-index: 1;

  .navigation-bar--bg-curve {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 184px;
    z-index: 1;
  }
  .navigation-bar--bg-img {
    display: block;
    position: relative;
    top: 50%;
    left: 50%;
    min-width: 100%;
    max-width: 100%;
    z-index: 0;
    transform: translate(-50%, -50%);
  }
}

.navigation-bar--left {
  display: inline-block;
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 2;

  .navigation-bar--btn-container {
    margin-left: 25px;
  }

  .navigation-bar--portal-logo {
    margin-right: 25px;
  }
}

.efficio-btn.efficio-button-cta--primary {
  border-color: #001969 !important;
  background-color: #001969 !important;
}
.efficio-btn.efficio-button-cta--tertiary {
  color: #001969 !important;
}
